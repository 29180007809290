import React from "react"
import { graphql, navigate } from "gatsby"
import { ItemBySlugQuery } from "../generated/graphql"
import Img, { FixedObject } from "gatsby-image"
import Currency from "../components/currency"
import { DL, DTD } from "../components/dl"
import idx from "idx"
import { default as MakeCurrency } from "../../lib/currency"
import usePersistedCart from "../hooks/use-persisted-cart"

const handleClick =
  (addItem: (sku: string, signed: string) => void, item: Item) =>
  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    addItem(item.sku, item.signed)
    navigate("/basket")
  }

const PageTemplate = ({ data }: { data: ItemBySlugQuery }) => {
  const { addItem } = usePersistedCart()

  if (!data) {
    throw new Error("Data Missing")
  }
  if (!data.site) {
    throw new Error("Site Missing")
  }
  if (!data.site.siteMetadata) {
    throw new Error("Site Metadata Missing")
  }
  if (!data.markdownRemark) {
    throw new Error("MarkdownRemark Missing")
  }
  if (!data.markdownRemark.frontmatter) {
    throw new Error("MarkdownRemark Frontmatter Missing")
  }
  if (!data.markdownRemark.fields) {
    throw new Error("MarkdownRemark Fields Missing")
  }
  const name = idx(data, (_) => _.markdownRemark.frontmatter.name)
  const commonName = idx(data, (_) => _.markdownRemark.frontmatter.common_name)
  const printSize = idx(data, (_) => _.markdownRemark.frontmatter.print_size)
  const price = idx(data, (_) => _.markdownRemark.frontmatter.price) || 0
  const image = idx(
    data,
    (_) => _.markdownRemark.frontmatter.image.childImageSharp.fixed
  )
  const fixedImage = image as FixedObject
  const category = idx(data, (_) => _.markdownRemark.frontmatter.category)
  const slug = idx(data, (_) => _.markdownRemark.frontmatter.slug)
  const html = idx(data, (_) => _.markdownRemark.html) || ""
  const sku = idx(data, (_) => _.markdownRemark.fields.sku)
  const signed = idx(data, (_) => _.markdownRemark.fields.signed)

  if (!category) throw "Item Missing Category"
  if (!name) throw "Item Missing Name"
  if (!price) throw "Item Missing Price"
  if (!signed) throw "Item Missing Signed"
  if (!sku) throw "Item Missing Sku"
  if (!slug) throw "Item Missing Slug"

  const item: Item = {
    category: category,
    name: name,
    price: price,
    signed: signed,
    sku: sku,
    slug: slug,
  }

  return (
    <div className="flex flex-wrap">
      <div className="flex border-box w-full md:w-1/2 xl:w-3/6 px-2 content-center justify-center">
        {image && <Img className="shadow-md " fixed={fixedImage} />}
      </div>
      <div className="flex content-start border-box flex-wrap w-full md:w-1/2 xl:w-3/6 px-2">
        <h3 className="w-full my-8 text-center text-5xl text-shadow font-fancy text-bm-brown">
          {name}
        </h3>
        <DL>
          {commonName && <DTD name="Common Name">{commonName}</DTD>}
          <DTD name="Print Size">{printSize}</DTD>
          <DTD name="Price">
            <Currency amount={MakeCurrency.fromDollars(price)} />
          </DTD>
        </DL>
        <div className="flex w-full justify-end">
          <button
            className="bg-bm-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleClick(addItem, item)}
          >
            Add To Cart
          </button>
        </div>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  )
}

export const query = graphql`
  query ItemBySlug($slug: String!, $category: String!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    markdownRemark(
      frontmatter: { category: { eq: $category } }
      fields: { slug: { eq: $slug } }
    ) {
      id
      html
      fields {
        sku
        signed
      }
      frontmatter {
        name
        category
        slug
        common_name
        print_size
        price
        image {
          childImageSharp {
            fixed(width: 400) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
